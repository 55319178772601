import ColorPicker from "../ColorPicker";
import { StrapTypeOptions, StrapTypes } from "../Types";
import Basic from './Basic';

export const StrapGradient = ({ type, ...props }) => {
  switch (type) {
    case StrapTypes.BASIC:
      return <Basic.Gradient {...props} />;
    // case StrapTypes.STITCH:
    //   return <Stitch.Gradient {...props} />;
    // case StrapTypes.METAL:
    //   return <Metal.Gradient {...props} />;
    default:
      break;
  }
};

export const Strap = ({ type, ...props }) => {
  switch (type) {
    case StrapTypes.BASIC:
      return <Basic.Strap {...props} />;
    // case StrapTypes.STITCH:
    //   return <Stitch.Strap {...props} />;
    // case StrapTypes.METAL:
    //   return <Metal.Strap {...props} />;
    default:
      break;
  }
};

export const StrapForm = ({
  type,
  colors: { stroke, gradient1, gradient2, gradient3 },
  onColorChange,
  onTypeChange,
}) => (
  <>
    <div className="col-md-12 mb-1 mt-3">
      <label htmlFor="strap" className="form-label d-inline-block w-25">
        <strong>Strap</strong>
      </label>
      <select
        className="form-select d-inline-block w-50"
        name="strap"
        onChange={onTypeChange}
        value={type}
      >
        {Object.keys(StrapTypeOptions).map((key) => (
          <option key={key} value={key}>
            {StrapTypeOptions[key]}
          </option>
        ))}
      </select>
    </div>
    <div className="col-md-3 d-flex align-items-center mt-0 color-input">
      <label htmlFor="strap" className="form-label">
        Stroke
      </label>
      <ColorPicker
        name="strap"
        property="stroke"
        color={stroke}
        onChange={onColorChange}
      />
    </div>
    <div className="col-md-4 d-flex align-items-center mt-0 color-input">
      <label htmlFor="strap" className="form-label">
        Gradient 1
      </label>
      <ColorPicker
        name="strap"
        property="gradient1"
        color={gradient1}
        onChange={onColorChange}
      />
    </div>
    <div className="col-md-4 d-flex align-items-center mt-0 color-input">
      <label htmlFor="strap" className="form-label">
        Gradient 2
      </label>
      <ColorPicker
        name="strap"
        property="gradient2"
        color={gradient2}
        onChange={onColorChange}
      />
    </div>
    <div className="col-md-4 d-flex align-items-center mt-0 color-input">
      <label htmlFor="strap" className="form-label">
        Gradient 3
      </label>
      <ColorPicker
        name="strap"
        property="gradient3"
        color={gradient3}
        onChange={onColorChange}
      />
    </div>
  </>
);