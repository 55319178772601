import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Builder } from "./Builder";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Builder />} />
      </Routes>
    </>
  );
}

export default App;
