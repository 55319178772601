const Number = ({ colors: { fill, stroke } }) => (
  <g>
    <text
      className="minute-marker"
      transform="translate(584.855 1038.4)"
      fill={fill}
      strokeOpacity={1}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={15}>{"60"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m643.714 1024.09-1.756 16.709M686.949 1030.939l-3.493 16.433M729.23 1042.268l-5.19 15.978M770.097 1057.955l-6.833 15.348"
    />
    <text
      className="minute-marker"
      transform="rotate(30 -1630.267 2012.745)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={15}>{"05"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m845.812 1101.67-9.875 13.59M879.83 1129.217l-11.241 12.485M910.783 1160.17l-12.485 11.241M938.33 1194.188l-13.59 9.875"
    />
    <text
      className="minute-marker"
      transform="rotate(60 -591.89 1427.614)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"10"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m982.045 1269.903-15.348 6.833M997.732 1310.77l-15.978 5.19M1009.061 1353.051l-16.433 3.493M1015.91 1396.286l-16.709 1.756"
    />
    <text
      className="minute-marker"
      transform="rotate(90 -211.716 1213.316)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={15}>{"15"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m1015.909 1483.714-16.708-1.756M1009.061 1526.949l-16.433-3.493M997.732 1569.23l-15.978-5.19M982.045 1610.097l-15.348-6.833"
    />
    <text
      className="minute-marker"
      transform="rotate(120 7.812 1089.633)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"20"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m938.33 1685.812-13.59-9.875M910.783 1719.83l-12.485-11.241M879.83 1750.783l-11.241-12.485M845.812 1778.33l-9.875-13.59"
    />
    <text
      className="minute-marker"
      transform="rotate(150 168.223 999.218)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"25"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m770.097 1822.045-6.833-15.348M729.23 1837.732l-5.19-15.978M686.949 1849.061l-3.493-16.433M643.714 1855.91l-1.756-16.709"
    />
    <text
      className="minute-marker"
      transform="rotate(179.983 307.447 920.8)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"30"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m556.286 1855.91 1.756-16.709M513.051 1849.061l3.493-16.433M470.77 1837.732l5.19-15.978M429.903 1822.045l6.833-15.348"
    />
    <text
      className="minute-marker"
      transform="rotate(-150 446.48 842.388)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"35"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m354.188 1778.33 9.875-13.59M320.17 1750.783l11.24-12.485M289.217 1719.83l12.485-11.241M261.669 1685.812l13.592-9.875"
    />
    <text
      className="minute-marker"
      transform="rotate(-120 607.06 751.809)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"40"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m217.955 1610.097 15.348-6.833M202.268 1569.23l15.978-5.19M190.939 1526.949l16.433-3.493M184.09 1483.714l16.709-1.756"
    />
    <text
      className="minute-marker"
      transform="rotate(-90 826.413 628.013)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"45"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m184.09 1396.286 16.709 1.756M190.939 1353.051l16.433 3.493M202.268 1310.77l15.978 5.19M217.955 1269.903l15.348 6.833"
    />
    <text
      className="minute-marker"
      transform="rotate(-60 1206.687 414.013)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"50"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m261.67 1194.188 13.59 9.875M289.217 1160.17l12.485 11.241M320.17 1129.217l11.241 12.485M354.188 1101.67l9.875 13.59"
    />
    <text
      className="minute-marker"
      transform="rotate(-30 2245.001 -171.495)"
      fill={fill}
      strokeOpacity={0}
      stroke={stroke}
      strokeWidth={0}
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fontFamily="Monda"
      fontSize={24}
      fontSizeAdjust={0.318}
      letterSpacing={0}
      textAnchor="middle"
      wordSpacing={0}
    >
      <tspan x={14.648}>{"55"}</tspan>
    </text>
    <path
      className="minute-marker"
      fill="none"
      stroke={fill}
      strokeWidth={2.4}
      strokeLinecap="square"
      strokeMiterlimit={2}
      d="m429.903 1057.955 6.833 15.348M470.77 1042.268l5.19 15.978M513.051 1030.939l3.493 16.433M556.286 1024.09l1.756 16.709"
    />
  </g>
);


export default Number;