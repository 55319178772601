const Gradient = ({ isAnimated, colors: { gradient1, gradient2, gradient3 } }) => (
  <>
    <linearGradient
      className="strap-gradient"
      id="c"
      gradientUnits="objectBoundingBox"
      x1={0.51}
      y1={0}
      x2={0.499}
      y2={1}
      spreadMethod="pad"
    >
      <stop
        stopColor={gradient2}
        className="stop-2"
        offset={0}
      />
      <stop
        stopColor={gradient1}
        className="stop-1"
        offset={0.331}
      />
      <stop
        stopColor={gradient3}
        className="stop-3"
        offset={1}
      />
    </linearGradient>
    <linearGradient
      className="strap-gradient"
      id="d"
      gradientUnits="objectBoundingBox"
      x1={0.5}
      y1={0.013}
      x2={0.5}
      y2={0.996}
      spreadMethod="pad"
    >
      <stop
        stopColor={gradient1}
        className="stop-1"
        offset={0}
      />
      <stop
        stopColor={gradient2}
        className="stop-2"
        offset={0.486}
      />
      <stop
        stopColor={gradient3}
        className="stop-3"
        offset={1}
      />
    </linearGradient>
  </>
);

const Strap = ({ colors: { fill, stroke } }) => (
  <>
    <path
      className="strap"
      transform="matrix(.31893 0 0 .31893 323.382 487.504)"
      fill="url(#c)"
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth={23.869}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M845.787.02c-114.08.307-576.032 5.423-666.142 67.306L4.385 1290.65s-117.099 92.88 859.029 93.38v.03h7.844v-.03c976.132-.5 859.032-93.38 859.032-93.38L1555.05 67.326C1456.03-.673 913.241-.13 871.258.02h-7.844c-3.782-.017-6.353-.035-17.627 0Z"
    />
    <path
      className="strap"
      transform="matrix(.31893 0 0 .31893 315.165 1950.645)"
      fill="url(#d)"
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth={23.76}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M870.91 2034.15c-117.469-.36-593.143-5.6-685.928-69.35L4.515 96.192S-116.062.56 889.06.027V0h8.076v.027C1902.26.56 1781.68 96.192 1781.68 96.192L1601.24 1964.8c-101.96 70.07-660.872 69.44-704.103 69.35H870.91Z"
    />
  </>
);

export default {
  Gradient,
  Strap,
};