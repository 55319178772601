const Arabic = ({ colors: { fill, stroke } }) => (
  <g
    className="hour-marker"
    fill={fill}
    strokeOpacity={1}
    stroke={stroke}
    strokeWidth={5}
    strokeLinecap="square"
    strokeLinejoin="bevel"
    fontFamily="Amiri"
    fontSize={170}
    fontSizeAdjust={0.44}
    fontWeight={500}
    textAnchor="middle"
    wordSpacing={0}
  >
    <text transform="translate(532.809 1161.837)" letterSpacing={-50}>
      <tspan x={43.207}>{"\u0661\u0662"}</tspan>
    </text>
    <text transform="translate(353.674 1214.68)" letterSpacing={-50}>
      <tspan x={43.207}>{"\u0661\u0661"}</tspan>
    </text>
    <text transform="translate(262.93 1320.1)" letterSpacing={-40}>
      <tspan x={43.207}>{"\u0661\u0660"}</tspan>
    </text>
    <text transform="translate(210.597 1478.037)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0669"}</tspan>
    </text>
    <text transform="translate(282.196 1630.298)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0668"}</tspan>
    </text>
    <text transform="translate(391.123 1753.768)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0667"}</tspan>
    </text>
    <text transform="translate(551.557 1794.957)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0666"}</tspan>
    </text>
    <text transform="translate(725.39 1757.272)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0665"}</tspan>
    </text>
    <text transform="translate(839.94 1637.088)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0664"}</tspan>
    </text>
    <text transform="translate(888.917 1478.957)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0663"}</tspan>
    </text>
    <text transform="rotate(-.066 1154007.418 -730477.465)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0662"}</tspan>
    </text>
    <text transform="translate(731.697 1207.185)" letterSpacing={0}>
      <tspan x={43.207}>{"\u0661"}</tspan>
    </text>
  </g>
);

export default Arabic;
