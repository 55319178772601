const Gradient = ({ isAnimated, colors: { gradient1, gradient2 } }) => (
  <>
    <linearGradient
      className="lug-gradient"
      id="a"
      gradientUnits="objectBoundingBox"
      x1={0.498}
      y1={0.019}
      x2={0.505}
      y2={1}
      spreadMethod="pad"
    >
      <stop stopColor={gradient1} className="stop-1" offset={0}>
        {isAnimated && (
          <animate
            attributeName="stop-color"
            values={[
              gradient1,
              gradient2,
              gradient1,
            ].join(";")}
            dur="4s"
            repeatCount="indefinite"
          />
        )}
      </stop>
      <stop stopColor={gradient2} className="stop-2" offset={1}>
        {isAnimated && (
          <animate
            attributeName="stop-color"
            values={[
              gradient2,
              gradient1,
              gradient2,
            ].join(";")}
            dur="4s"
            repeatCount="indefinite"
          />
        )}
      </stop>
    </linearGradient>
    <linearGradient
      className="lug-gradient"
      id="b"
      gradientUnits="objectBoundingBox"
      x1={0.504}
      y1={0}
      x2={0.502}
      y2={1.019}
      spreadMethod="pad"
    >
      <stop stopColor={gradient2} className="stop-2" offset={0}>
        {isAnimated && (
          <animate
            attributeName="stop-color"
            values={[
              gradient2,
              gradient1,
              gradient2,
            ].join(";")}
            dur="4s"
            repeatCount="indefinite"
          />
        )}
      </stop>
      <stop stopColor={gradient1} className="stop-1" offset={1}>
        {isAnimated && (
          <animate
            attributeName="stop-color"
            values={[
              gradient1,
              gradient2,
              gradient1,
            ].join(";")}
            dur="4s"
            repeatCount="indefinite"
          />
        )}
      </stop>
    </linearGradient>
  </>
);

const Lug = ({ colors: { fill, stroke} }, props) => (
  <>
    <path
      className="lug"
      transform="matrix(.2934 0 0 .2934 95.946 828.94)"
      fill="url(#a)"
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth={23.869}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1543.43 7.53v1.72L629.137.03S-62.935 1587.88 4.646 2040.16c600.841-7.66 1241.144 4.38 1538.654 11.28v4.31s60.79-1.28 93.6-2c27.26.72 78.06 2 78.06 2v-3.66c333.62-6.7 1108.11-19.89 1732.66-11.93C3515.2 1587.88 2823.77 0 2823.77 0L1725.4 9l-.03-1.47-83.94.78ZM798.087 194.91c1.62.08 1.93 4.96 1.93 4.97l845.163-4.76 1007.03 4.76c0-.01 5.22-77.99 78 384.68-311.91-244.33-998.12-244.34-998.12-244.34l-11.91 1.12v-1.12s-686.21.01-998.123 244.34c54.58-347 71.15-389.9 76.03-389.65Z"
    />
    <path
      className="lug"
      transform="matrix(.2934 0 0 .2934 91.165 1450.114)"
      fill="url(#b)"
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth={23.869}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1543.43 2048.22v-1.72l-914.968-8.66S-62.931 467.87 4.65 15.59c600.842 7.66 1241.14-4.38 1538.65-11.28V0s60.79 1.28 93.6 2c27.26-.72 78.06-2 78.06-2v3.66c333.62 6.7 1108.11 19.89 1732.66 11.93 67.58 452.28-623.85 2022.25-623.85 2022.25l-1098.37 8.91-.03 1.47-83.94-.78Zm-745.338-187.38c1.62-.08 1.93-4.96 1.93-4.97l845.158 4.75 1007.03-4.75c0 .01 5.22 77.99 78-384.68-311.91 244.33-998.12 244.34-998.12 244.34l-11.91-1.12v1.12s-686.21-.01-998.128-244.34c54.59 347 71.16 389.9 76.04 389.65Z"
    />
  </>
);

export default {
  Gradient,
  Lug
}