const Triangle = ({ colors: { fill, stroke } }) => (
  <>
    <g
      className="hour-marker"
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fill={stroke}
      stroke={stroke}
      strokeWidth={4.8}
    >
      <path d="M623.46 1051.68h-46.92L600 1169.648ZM814.477 1115.435l-40.634-23.46-38.667 113.893ZM948.025 1266.157l-23.46-40.634-90.433 79.301ZM988.32 1463.46v-46.92L870.352 1440ZM924.565 1654.477l23.46-40.634-113.893-38.667ZM773.843 1788.025l40.634-23.46-79.301-90.433ZM576.54 1828.32h46.92L600 1710.352ZM385.523 1764.565l40.634 23.46 38.667-113.893ZM251.975 1613.843l23.46 40.634 90.433-79.301ZM211.68 1416.54v46.92L329.648 1440ZM275.435 1225.523l-23.46 40.634 113.893 38.667ZM426.157 1091.975l-40.634 23.46 79.301 90.433Z" />
    </g>
    <g
      className="hour-marker"
      strokeLinecap="square"
      strokeLinejoin="bevel"
      fill={fill}
      stroke={fill}
      strokeWidth={2.4}
    >
      <path d="M611.434 1050.54 600 1117.14l-11.434-66.6ZM804.632 1108.435l-43.202 51.96 23.398-63.394ZM943 1255.172l-63.395 23.398 51.96-43.202ZM989.46 1451.434 922.86 1440l66.6-11.434ZM931.565 1644.632l-51.96-43.202 63.394 23.398ZM784.828 1783l-23.398-63.395 43.202 51.96ZM588.566 1829.46l11.434-66.6 11.434 66.6ZM395.368 1771.565l43.202-51.96-23.398 63.394ZM257 1624.828l63.395-23.398-51.96 43.202ZM210.54 1428.566l66.6 11.434-66.6 11.434ZM268.435 1235.368l51.96 43.202-63.394-23.398ZM415.172 1097l23.398 63.395-43.202-51.96Z" />
    </g>
  </>
);

export default Triangle;