import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

function ColorPicker({name, property, color, onChange}) {
  const [isShown, setIsShown] = useState(false);

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        border: "1px solid #c4c4c482",
        background: color,
        cursor: "pointer",
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const handleClick = () => {
    setIsShown(!isShown);
  };

  const handleClose = () => {
    setIsShown(false);
  };

  return (
    <>
      <div style={styles.color} onClick={handleClick}/>
      {isShown ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={onChange(name, property)} />
        </div>
      ) : null}
    </>
  );
}

export default ColorPicker;