const Rolex = ({ colors: { fill, stroke } }) => (
  <path
    className="hour-marker"
    fill={fill}
    fillRule="evenodd"
    stroke={stroke}
    strokeWidth={4.8}
    strokeLinecap="square"
    strokeLinejoin="bevel"
    d="M585.6 1051.32h28.8v108h-28.8zM781.87 1096.193l24.94 14.4-54 93.531-24.94-14.4zM929.407 1233.19l14.4 24.94-93.531 54-14.4-24.94zM988.68 1425.6v28.8h-108v-28.8zM943.807 1621.87l-14.4 24.94-93.531-54 14.4-24.94zM806.81 1769.407l-24.94 14.4-54-93.531 24.94-14.4zM614.4 1828.68h-28.8v-108h28.8zM418.13 1783.807l-24.94-14.4 54-93.531 24.94 14.4zM270.593 1646.81l-14.4-24.94 93.531-54 14.4 24.94zM211.32 1454.4v-28.8h108v28.8zM256.193 1258.13l14.4-24.94 93.531 54-14.4 24.94zM393.19 1110.593l24.94-14.4 54 93.531-24.94 14.4z"
  />
);

export default Rolex;