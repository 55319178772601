import React, { useState } from "react";
import BuilderForm from "./BuilderForm";
import { HourTypes, LugTypes, MinuteTypes, StrapTypes } from "./Types";
import PenduloWatch from "./Watch";

export function Builder() {
  const [isAnimated, setIsAnimated] = useState(false);
  const [isReflect, setIsReflect] = useState(false);
  const [types, setTypes] = useState({
    lug: LugTypes.OPEN1,
    strap: StrapTypes.BASIC,
    minuteMarker: MinuteTypes.DOT,
    hourMarker: HourTypes.ROLEX,
  });
  const [colors, setColors] = useState({
    hourMarker: {
      stroke: "#e0d15e",
      fill: "#fff",
    },
    minuteMarker: {
      stroke: "#000",
      fill: "#fff",
    },
    lug: {
      stroke: "#D2C359",
      fill: null,
      gradient1: "#f9e96b",
      gradient2: "#aea240",
    },
    strap: {
      stroke: "#42464c",
      fill: null,
      gradient1: "#4a4e54",
      gradient2: "#606264",
      gradient3: "#4a4e54",
    },
    background: {
      stroke: "#000",
      fill: null,
      gradient1: "#3d3c27",
      gradient2: "#040404",
    },
    outerFrame: {
      stroke: "#D2C359",
      fill: "#f9e96b",
      gradient1: "#f9e96b",
      gradient2: "#aea240",
    },
    innerFrame: {
      stroke: "#eadb65",
      fill: "#eadb65",
      gradient1: null,
      gradient2: null,
    },
    logo: {
      stroke: "#000000",
      fill: "#e0d15e",
      gradient1: null,
      gradient2: null,
    },
  });

  return (
    <>
      <div className="fixed-top text-center py-3">
        <p>
          Welcome to Pendulo Builder!
        </p>
      </div>
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="watch col-xl-5 col-lg-6 col-md-6 text-center">
              <PenduloWatch
                types={types}
                colors={colors}
                isAnimated={isAnimated}
                isReflect={isReflect}
              />
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
              <div className="card w-100 h-75 overflow-auto">
                <div className="card-body">
                  <BuilderForm
                    types={types}
                    setTypes={setTypes}
                    colors={colors}
                    setColors={setColors}
                    isAnimated={isAnimated}
                    setIsAnimated={setIsAnimated}
                    isReflect={isReflect}
                    setIsReflect={setIsReflect}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
