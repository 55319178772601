const Dot = ({ colors: { fill, stroke } }) => (
  <g
    className="minute-marker"
    fill={fill}
    fillRule="evenodd"
    stroke={stroke}
    strokeWidth={2.4}
    strokeLinecap="square"
    strokeLinejoin="bevel"
  >
    <circle
      transform="translate(593.88 1020.72)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(6 -9435.189 6595.593)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(12 -4552.69 3754.835)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(18 -2919.226 2804.445)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(24 -2097.995 2326.633)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(30 -1601.63 2037.835)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(36 -1267.667 1843.527)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(42 -1026.473 1703.195)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(48 -843.227 1596.578)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(54 -698.578 1512.418)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(60 -580.91 1443.955)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(66 -482.825 1386.887)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(72 -399.39 1338.343)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(78 -327.182 1296.33)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(84 -263.752 1259.425)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(90 -207.3 1226.58)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(96 -156.47 1197.006)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(102 -110.221 1170.097)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(108 -67.738 1145.38)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(114 -28.372 1122.475)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(120 8.403 1101.078)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(126 43.019 1080.938)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(132 75.833 1061.846)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(138 107.151 1043.625)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(144 137.234 1026.122)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(150 166.31 1009.205)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(156 194.58 992.757)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(162 222.227 976.67)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(168 249.419 960.85)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(174 276.313 945.202)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(180 303.06 929.64)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-174 329.807 914.078)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-168 356.701 898.43)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-162 383.893 882.61)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-156 411.54 866.523)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-150 439.81 850.075)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-144 468.886 833.158)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-138 498.969 815.655)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-132 530.287 797.434)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-126 563.101 778.342)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-120 597.716 758.202)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-114 634.492 736.805)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-108 673.858 713.9)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-102 716.341 689.183)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-96 762.59 662.274)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-90 813.42 632.7)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-84 869.872 599.855)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-78 933.302 562.95)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-72 1005.51 520.937)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-66 1088.945 472.393)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-60 1187.03 415.325)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-54 1304.698 346.862)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-48 1449.347 262.702)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-42 1632.593 156.085)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-36 1873.787 15.753)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-30 2207.75 -178.555)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-24 2704.115 -467.353)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-18 3525.346 -945.165)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-12 5158.81 -1895.555)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
    <circle
      transform="rotate(-6 10041.308 -4736.312)"
      cx={6.12}
      cy={6.12}
      r={6.12}
    />
  </g>
);

export default Dot;