import ColorPicker from "../ColorPicker";
import { LugTypeOptions, LugTypes } from "../Types";
import Open1 from './Open1';

export const LugGradient = ({ type, ...props }) => {
  switch (type) {
    case LugTypes.OPEN1:
      return <Open1.Gradient {...props} />;
    case LugTypes.OPEN2:
      return <Open2.Gradient {...props} />;
    case LugTypes.CLOSE1:
      return <Close1.Gradient {...props} />;
    case LugTypes.CLOSE2:
      return <Close2.Gradient {...props} />;
    default:
      break;
  }
};

export const Lug = ({ type, ...props }) => {
  switch (type) {
    case LugTypes.OPEN1:
      return <Open1.Lug {...props} />;
    // case LugTypes.OPEN2:
    //   return <Open2.Lug {...props} />;
    // case LugTypes.CLOSE1:
    //   return <Close1.Lug {...props} />;
    // case LugTypes.CLOSE2:
    //   return <Close2.Lug {...props} />;
    default:
      break;
  }
};

export const LugForm = ({
  type,
  colors: { stroke, gradient1, gradient2 },
  onColorChange,
  onTypeChange,
}) => (
  <>
    <div className="col-md-12 mb-1 mt-3">
      <label htmlFor="lug" className="form-label d-inline-block w-25">
        <strong>Lug</strong>
      </label>
      <select
        className="form-select d-inline w-50"
        name="lug"
        onChange={onTypeChange}
        value={type}
      >
        {Object.keys(LugTypeOptions).map((key) => (
          <option key={key} value={key}>
            {LugTypeOptions[key]}
          </option>
        ))}
      </select>
    </div>
    <div className="col-md-3 d-flex align-items-center mt-0 color-input">
      <label htmlFor="lug" className="form-label">
        Stroke
      </label>
      <ColorPicker
        name="lug"
        property="stroke"
        color={stroke}
        onChange={onColorChange}
      />
    </div>
    <div className="col-md-4 d-flex align-items-center mt-0 color-input">
      <label htmlFor="lug" className="form-label">
        Gradient 1
      </label>
      <ColorPicker
        name="lug"
        property="gradient1"
        color={gradient1}
        onChange={onColorChange}
      />
    </div>
    <div className="col-md-4 d-flex align-items-center mt-0 color-input">
      <label htmlFor="lug" className="form-label">
        Gradient 2
      </label>
      <ColorPicker
        name="lug"
        property="gradient2"
        color={gradient2}
        onChange={onColorChange}
      />
    </div>
  </>
);