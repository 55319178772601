export const LugTypes = {
  OPEN1: 'OPEN1',
  OPEN2: 'OPEN2',
  CLOSE1: 'CLOSE1',
  CLOSE2: 'CLOSE2',
};

export const LugTypeOptions = {
  [LugTypes.OPEN1]: "Open 1",
  // [LugTypes.OPEN2]: "Open 2",
  // [LugTypes.CLOSE1]: "Close 1",
  // [LugTypes.CLOSE2]: "Close 2",
};

export const StrapTypes = {
  BASIC: "BASIC",
  STITCH: "STITCH",
  METAL: "METAL",
};

export const StrapTypeOptions = {
  [StrapTypes.BASIC]: "Basic",
  // [StrapTypes.STITCH]: "Stitch",
  // [StrapTypes.METAL]: "Metal",
};

export const HourTypes = {
  ROMAN: "ROMAN",
  ROLEX: "ROLEX",
  AP: "AP",
  ARABIC: "ARABIC",
  NUMBER: "NUMBER",
  TRIANGLE: "TRIANGLE",
};

export const HourTypeOptions = {
  // [HourTypes.ROMAN]: "Roman Numerals",
  [HourTypes.ROLEX]: "Rolex",
  [HourTypes.AP]: "Audemars Piguet",
  [HourTypes.ARABIC]: "Arabic",
  // [HourTypes.NUMBER]: "Number",
  [HourTypes.TRIANGLE]: "Triangle",
};

export const MinuteTypes = {
  DASH: "DASH",
  DOT: "DOT",
  NUMBER: "NUMBER",
};

export const MinuteTypeOptions = {
  [MinuteTypes.DASH]: "Dash",
  [MinuteTypes.DOT]: "Dot",
  [MinuteTypes.NUMBER]: "Number",
};