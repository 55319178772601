import ColorPicker from "../ColorPicker";
import { HourTypeOptions, HourTypes } from "../Types";
import AP from "./AP";
import Arabic from "./Arabic";
import Rolex from "./Rolex";
import Triangle from "./Triangle";

export const HourMarker = ({ type, ...props }) => {
  switch (type) {
    // case HourTypes.ROMAN:
    //   return <Roman {...props} />;
    case HourTypes.ROLEX:
      return <Rolex {...props} />;
    case HourTypes.AP:
      return <AP {...props} />;
    case HourTypes.ARABIC:
      return <Arabic {...props} />;
    // case HourTypes.NUMBER:
    //   return <Number {...props} />;
    case HourTypes.TRIANGLE:
      return <Triangle {...props} />;
    default:
      break;
  }
};

export const HourMarkerForm = ({
  type,
  colors: { fill, stroke },
  onColorChange,
  onTypeChange,
}) => (
  <>
    <div className="col-md-12 mb-1 mt-3">
      <label htmlFor="hourMarker" className="form-label d-inline-block w-25">
        <strong>Hour</strong>
      </label>
      <select
        className="form-select d-inline w-50"
        name="hourMarker"
        onChange={onTypeChange}
        value={type}
      >
        {Object.keys(HourTypeOptions).map((key) => (
          <option key={key} value={key}>
            {HourTypeOptions[key]}
          </option>
        ))}
      </select>
    </div>
    <div className="col-md-3 d-flex align-items-center mt-0 color-input">
      <label htmlFor="hourMarker" className="form-label">
        Stroke
      </label>
      <ColorPicker
        name="hourMarker"
        property="stroke"
        color={stroke}
        onChange={onColorChange}
      />
    </div>
    <div className="col-md-3 d-flex align-items-center mt-0 color-input">
      <label htmlFor="hourMarker" className="form-label">
        Fill
      </label>
      <ColorPicker
        name="hourMarker"
        property="fill"
        color={fill}
        onChange={onColorChange}
      />
    </div>
  </>
);