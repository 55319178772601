import * as React from "react";
import ColorPicker from "./ColorPicker";
import { HourMarkerForm } from "./HourMarker/Master";
import { LugForm } from "./Lug/Master";
import { MinuteMarkerForm } from "./MinuteMarker/Master";
import { StrapForm } from "./Strap/Master";
import { HourTypeOptions, LugTypeOptions, MinuteTypeOptions, StrapTypeOptions } from "./Types";

const BuilderForm = ({
  types,
  setTypes,
  colors,
  setColors,
  isAnimated,
  setIsAnimated,
  isReflect,
  setIsReflect,
}) => {
  const handleColorChange = (name, property) => {
    return (color) => {
      let newColors = { ...colors };
      newColors[name][property] = color.hex;
      setColors(newColors);
    };
  };

  const handleTypeChange = (event) => {
    const typeName = event.target.getAttribute("name");
    const type = event.target.value;

    const newTypes = { ...types, [typeName]: type };
    setTypes(newTypes);
  };

  return (
    <form className="row g-3">
      <div className="col-md-12">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="animateCheckbox"
            value="animate"
            checked={isAnimated}
            onChange={() => setIsAnimated(!isAnimated)}
          />
          <label className="form-check-label" htmlFor="animateCheckbox">
            Animate
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="reflectCheckbox"
            value="reflect"
            checked={isReflect}
            onChange={() => setIsReflect(!isReflect)}
          />
          <label className="form-check-label" htmlFor="reflectCheckbox">
            Reflect
          </label>
        </div>
      </div>
      <HourMarkerForm
        type={types?.hourMarker}
        colors={colors?.hourMarker}
        onColorChange={handleColorChange}
        onTypeChange={handleTypeChange}
      />
      <MinuteMarkerForm
        type={types?.minuteMarker}
        colors={colors?.minuteMarker}
        onColorChange={handleColorChange}
        onTypeChange={handleTypeChange}
      />
      <LugForm
        type={types?.lug}
        colors={colors?.lug}
        onColorChange={handleColorChange}
        onTypeChange={handleTypeChange}
      />
      <StrapForm
        type={types?.strap}
        colors={colors?.strap}
        onColorChange={handleColorChange}
        onTypeChange={handleTypeChange}
      />
      <div className="col-md-12 mb-1 mt-3">
        <label htmlFor="background" className="form-label">
          <strong>Background</strong>
        </label>
      </div>
      <div className="col-md-3 d-flex align-items-center mt-0 color-input">
        <label htmlFor="background" className="form-label">
          Stroke
        </label>
        <ColorPicker
          name="background"
          property="stroke"
          color={colors?.background?.stroke}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="background" className="form-label">
          Gradient 1
        </label>
        <ColorPicker
          name="background"
          property="gradient1"
          color={colors?.background?.gradient1}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="background" className="form-label">
          Gradient 2
        </label>
        <ColorPicker
          name="background"
          property="gradient2"
          color={colors?.background?.gradient2}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-12 mb-1 mt-3">
        <label htmlFor="outerFrame" className="form-label">
          <strong>Outer Frame</strong>
        </label>
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="outerFrame" className="form-label">
          Stroke
        </label>
        <ColorPicker
          name="outerFrame"
          property="stroke"
          color={colors?.outerFrame?.stroke}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="outerFrame" className="form-label">
          Fill
        </label>
        <ColorPicker
          name="outerFrame"
          property="fill"
          color={colors?.outerFrame?.fill}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="outerFrame" className="form-label">
          Gradient 1
        </label>
        <ColorPicker
          name="outerFrame"
          property="gradient1"
          color={colors?.outerFrame?.gradient1}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="outerFrame" className="form-label">
          Gradient 2
        </label>
        <ColorPicker
          name="outerFrame"
          property="gradient2"
          color={colors?.outerFrame?.gradient2}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-12 mb-1 mt-3">
        <label htmlFor="innerFrame" className="form-label">
          <strong>Inner Frame</strong>
        </label>
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="innerFrame" className="form-label">
          Stroke
        </label>
        <ColorPicker
          name="innerFrame"
          property="stroke"
          color={colors?.innerFrame?.stroke}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="innerFrame" className="form-label">
          Fill
        </label>
        <ColorPicker
          name="innerFrame"
          property="fill"
          color={colors?.innerFrame?.fill}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-12 mb-1 mt-3">
        <label htmlFor="logo" className="form-label">
          <strong>Logo</strong>
        </label>
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="logo" className="form-label">
          Stroke
        </label>
        <ColorPicker
          name="logo"
          property="stroke"
          color={colors?.logo?.stroke}
          onChange={handleColorChange}
        />
      </div>
      <div className="col-md-4 d-flex align-items-center mt-0 color-input">
        <label htmlFor="logo" className="form-label">
          Fill
        </label>
        <ColorPicker
          name="logo"
          property="fill"
          color={colors?.logo?.fill}
          onChange={handleColorChange}
        />
      </div>
    </form>
  );
};

export default BuilderForm;
