const AP = ({ colors: { fill, stroke } }) => (
  <path
    className="hour-marker"
    fill={fill}
    stroke={stroke}
    strokeWidth={4.8}
    strokeLinecap="square"
    strokeLinejoin="bevel"
    d="M584.88 1050.48h10.08v122.4h-10.08zM604.08 1050.48h10.08v122.4h-10.08zM790.395 1100.146l8.73 5.04-61.2 106.001-8.73-5.04zM934.814 1240.875l5.04 8.73-106.001 61.2-5.04-8.73zM989.52 1434.96v10.08h-122.4v-10.08zM939.854 1630.395l-5.04 8.73-106.001-61.2 5.04-8.73zM799.125 1774.814l-8.73 5.04-61.2-106.001 8.73-5.04zM605.04 1829.52h-10.08v-122.4h10.08zM409.605 1779.854l-8.73-5.04 61.2-106.001 8.73 5.04zM265.186 1639.125l-5.04-8.73 106.001-61.2 5.04 8.73zM260.146 1249.605l5.04-8.73 106.001 61.2-5.04 8.73zM400.875 1105.186l8.73-5.04 61.2 106.001-8.73 5.04zM210.48 1445.04v-10.08h122.4v10.08z"
  />
);

export default AP;