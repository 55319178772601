import { MinuteTypeOptions, MinuteTypes } from "../Types";
import Number from "./Number";
import Dot from "./Dot";
import Dash from "./Dash";
import ColorPicker from "../ColorPicker";

export const MinuteMarker = ({ type, ...props }) => {
  switch (type) {
    case MinuteTypes.DASH:
      return <Dash {...props} />;
    case MinuteTypes.DOT:
      return <Dot {...props} />;
    case MinuteTypes.NUMBER:
      return <Number {...props} />;
    default:
      break;
  }
};

export const MinuteMarkerForm = ({
  type,
  colors: { fill, stroke },
  onColorChange,
  onTypeChange,
}) => (
  <>
    <div className="col-md-12 mb-1 mt-3">
      <label htmlFor="minuteMarker" className="form-label d-inline-block w-25">
        <strong>Minute</strong>
      </label>
      <select
        className="form-select d-inline w-50"
        name="minuteMarker"
        onChange={onTypeChange}
        value={type}
      >
        {Object.keys(MinuteTypeOptions).map((key) => (
          <option key={key} value={key}>
            {MinuteTypeOptions[key]}
          </option>
        ))}
      </select>
    </div>
    <div className="col-md-3 d-flex align-items-center mt-0 color-input">
      <label htmlFor="minuteMarker" className="form-label">
        Stroke
      </label>
      <ColorPicker
        name="minuteMarker"
        property="stroke"
        color={stroke}
        onChange={onColorChange}
      />
    </div>
    <div className="col-md-3 d-flex align-items-center mt-0 color-input">
      <label htmlFor="minuteMarker" className="form-label">
        Fill
      </label>
      <ColorPicker
        name="minuteMarker"
        property="fill"
        color={fill}
        onChange={onColorChange}
      />
    </div>
  </>
);